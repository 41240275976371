<template>
  <div class="main">
    <div class="topbar">
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <Select v-model="carTypeId"
                    clearable
                    placeholder="车辆类型"
                    class="m-r-10"
                    style="width:160px"
                    label-in-value
                    @on-change="onChangeCarType">
              <Option v-for="item in carTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <MyTreeSelect :value="departmentName"
                          placeholder="所属机构"
                          clearable
                          :data="departmentTreeArr"
                          class="m-r-10"
                          style="width:160px"
                          @onSelectChange="onChangeDepartment">
            </MyTreeSelect>
            <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
            <Select v-model="violateType"
                    clearable
                    placeholder="违规类型"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in violateTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <!-- <MyTreeSelect :value="gridName"
                          placeholder="网格名称"
                          clearable
                          class="m-r-10"
                          style="width:160px"
                          :data="gridNameTreeArr"
                          search
                          checked
                          @onCheckChange="onChangeGrid">
            </MyTreeSelect> -->
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/garbageTransit-exception/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/garbageTransit-exception/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex">
        <div class="left">
          <p>违规日历</p>
          <div class="calender">
            <el-calendar class="calendar-box"
                         v-model="calendar">
              <template slot="dateCell"
                        slot-scope="{data}">
                <!-- 非当月 -->
                <div v-if="data.type != 'current-month'">
                  <p class="calendar-top"
                     @click.stop="onClickDayList(data.day,data.type)">
                    {{ data.day.split('-')[2] }}
                    <Icon class="calendar-top-icon"
                          v-show="calendarDay == data.day"
                          custom="i-icon icon-wanchengtishi"
                          size="16"
                          color="#fff" />
                  </p>
                </div>
                <!-- 当月 -->
                <div v-else>
                  <p class="calendar-top"
                     :class="{success : contrastTime(data.day),warning : getCurrentDate(data.day),exception : getCurrentDate(data.day) && getCurrentDate(data.day).count == maxDateCount,}"
                     @click.stop="onClickDayList(data.day,data.type)">
                    {{ data.day.split('-')[2] }}
                    <Icon class="calendar-top-icon"
                          v-show="calendarDay == data.day"
                          custom="i-icon icon-wanchengtishi"
                          size="16"
                          color="#fff" />
                  </p>
                </div>

              </template>
            </el-calendar>
            <ul class="calender-bottom">
              <li>
                <div class="calender-color success"></div>
                <span>无违规</span>
              </li>
              <li>
                <div class="calender-color warning"></div>
                <span>有违规</span>
              </li>
              <li>
                <div class="calender-color exception"></div>
                <span>违规数最多</span>
              </li>
            </ul>
          </div>
          <p class="lh-40">整体违规情况</p>
          <ul class="left-list">
            <li class="left-list-item">
              <div class="fw-700 fs-28">{{errData.carCount}}</div>
              <span>车辆数</span>
              <Icon custom="i-icon icon-cheliangshiti"
                    size="24"
                    class="left-list-icon"></Icon>
            </li>
            <li class="left-list-item">
              <div class="fw-700 fs-28">{{errData.vCount}}</div>
              <span>违规总数</span>
              <Icon custom="i-icon icon-zongshushixin"
                    size="24"
                    class="left-list-icon"></Icon>
            </li>
            <li class="left-list-item">
              <div class="fw-700 fs-28">{{errData.top1 && errData.top1.count}}</div>
              <span>top1：{{errData.top1 && errData.top1.name}}</span>
              <Icon custom="i-icon icon-top"
                    size="24"
                    class="left-list-icon"></Icon>
            </li>
          </ul>
          <p class="lh-40">违规分布</p>
          <Echarts ref="echarts"
                   height="320px"
                   :options="options"
                   :data="violateChartData"
                   v-show="violateChartData.length"></Echarts>
          <div class="noData"
               v-show="violateChartData.length===0">
            <img src="../../../../assets/common/tubiaono.png">
            暂无数据
          </div>
        </div>
        <div class="table flex-1">
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <!-- <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template> -->
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"></PositionDetailModal>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import { formatDate } from '@/utils/dateRangUtil';
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    Echarts,
    MyTreeSelect,
    PositionDetailModal
  },
  data () {
    return {
      calendar: new Date(),
      carTypeName: '',
      carTypeId: '',
      // carTypeArr: [],
      carNumber: '',
      carNumberId: '',
      departmentName: '',
      departmentId: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      violateType: '',
      violateTypeArr: [
        {
          id: 0,
          name: '全部'
        },
      ],
      // gridName: '',
      options: {
        tooltip: {
          trigger: 'item'
        },
        dataset: {
          source: []
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
        },
        series: [
          {
            type: 'pie',
            radius: '60%',
            // data: [
            //   {
            //     value: 1,
            //     name: '速度限制'
            //   },
            //   {
            //     value: 2,
            //     name: '离岗'
            //   },
            // ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      violateChartData: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      columns: [
        {
          title: '车牌号',
          key: 'carName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规类型',
          key: 'ruleTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规时间',
          key: 'time',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规地点',
          key: 'address',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '违规内容',
          key: 'content',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      // 日历违规数组
      calendarArr: [],
      // 违规情况
      errData: {},
      // 整体违规情况与列表的开始时间
      startTime: '',
      endTime: '',
      // 日历的开始时间
      startCalendarTime: '',
      endCalendarTime: '',
      // 日历中违规数最多的数量
      maxDateCount: '',
      // 日历中点击的某天
      calendarDay: '',
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'carTreeArr': 'getCarTreeList',
      // 'gridNameTreeArr': 'getGridTreeList',
    }),
    getColumns () {
      return this.columns
    },
    carTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updateGridTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 220
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 220
      }
      // 日历组件上个月添加点击事件
      this.$nextTick(() => {
        // 点击前一个月
        let prevBtn = document.querySelector(
          '.el-calendar__button-group .el-button-group>button:nth-child(1)');
        prevBtn.addEventListener('click', () => {
          this.onClickMonthList()
        })
      })
      // 日历组件下个月添加点击事件
      this.$nextTick(() => {
        // 点击后一个月
        let prevBtn = document.querySelector(
          '.el-calendar__button-group .el-button-group>button:last-child');
        prevBtn.addEventListener('click', () => {
          if (this.contrastTime(this.calendar)) {
            this.onClickMonthList()
          } else {
            this.calendar = new Date()
            this.$Message.info({
              content: '超出时间范围'
            })
          }
        })
      })
      this.updateCarTypeAllList()
      this.updateCarTreeList()
      this.updateGridTreeList()
      this.timeInit()
      this.getList()
      this.getCarViolateEcharts()
      this.getCarViolateCalendar()
      this.getViolateTypeList()
    },
    // 获取违规类型列表
    getViolateTypeList () {
      this.$http.getWorkRuleItemList({ type: 5 }).then(res => {
        if (res.code === 200) {
          let arr = []
          arr = res.result.map(item => {
            item.id = item.sysName
            item.name = item.sysName
            return item
          })
          this.violateTypeArr = this.violateTypeArr.concat(arr)
        }
      })
    },
    // 时间初始化
    timeInit () {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      let day = new Date(year, month, 0)
      this.startTime = year + "-" + month + "-01"
      this.endTime = year + '-' + month + '-' + day.getDate()
      this.startCalendarTime = year + "-" + month + "-01"
      this.endCalendarTime = year + '-' + month + '-' + day.getDate()
    },
    // 点击日历中上月、下月时获取列表
    onClickMonthList () {
      let timeArr = formatDate(this.calendar).split('-')
      let day = new Date(timeArr[0], timeArr[1], 0)
      this.startTime = this.startCalendarTime = timeArr[0] + '-' + timeArr[1] + '-01'
      this.endTime = this.endCalendarTime = timeArr[0] + '-' + timeArr[1] + '-' + day.getDate()
      this.calendarDay = ''
      this.getList()
      this.getCarViolateEcharts()
      this.getCarViolateCalendar()
    },
    // 点击日历中的某一天
    onClickDayList (date, type) {
      // 点击的是今天之后的日期
      if (!this.contrastTime(date)) {
        this.calendar = new Date()
        this.$Message.info({
          content: '超出时间范围'
        })
        return
      } else {
        this.calendar = date
      }
      if (type != 'current-month') {
        let timeArr = date.split('-')
        let day = new Date(timeArr[0], timeArr[1], 0)
        this.startCalendarTime = timeArr[0] + '-' + timeArr[1] + '-01'
        this.endCalendarTime = timeArr[0] + '-' + timeArr[1] + '-' + day.getDate()
        this.getCarViolateCalendar()
      }
      if (this.startTime != this.endTime) {
        this.startTime = date
        this.endTime = date
        this.calendarDay = date
      } else {
        if (this.startTime == date && this.endTime == date) {
          this.calendarDay = ''
          let timeArr = date.split('-')
          let day = new Date(timeArr[0], timeArr[1], 0)
          this.startTime = this.startCalendarTime = timeArr[0] + '-' + timeArr[1] + '-01'
          this.endTime = this.endCalendarTime = timeArr[0] + '-' + timeArr[1] + '-' + day.getDate()
          this.startCalendarTime = timeArr[0] + '-' + timeArr[1] + '-01'
          this.endCalendarTime = timeArr[0] + '-' + timeArr[1] + '-' + day.getDate()
        } else {
          this.startTime = date
          this.endTime = date
          this.calendarDay = date
        }
      }
      this.getList()
      this.getCarViolateEcharts()
      this.getCarViolateCalendar()
    },
    // 点击的日期与当前日期对比
    contrastTime (date) {
      let oDate = new Date(date);
      if (Date.now() > oDate.getTime()) {
        return true
      } else {
        return false
      }
    },
    // 车辆类型选择器回调
    onChangeCarType (data) {
      if (data) {
        this.carTypeId = data.value
        this.carTypeName = data.label
      } else {
        this.carTypeId = ''
        this.carTypeName = ''
      }
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 选择网格
    // onChangeGrid (section) {
    //   let idArr = []
    //   let valueArr = []
    //   section.forEach(item => {
    //     if (item.type != 'depart') {
    //       idArr.push(item.id)
    //       valueArr.push(item.name)
    //     }
    //   })
    //   this.gridId = idArr.join()
    //   this.gridName = valueArr.join()
    // },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 获取当前日期
    getCurrentDate (date) {
      return this.calendarArr.find(item => item.date === date)
    },
    // 点击表格中的查看位置
    onClickAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carTypeId == 0 ? '' : this.carTypeId,
        carTypeName: this.carTypeId == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        startDate: this.startTime,
        endDate: this.endTime,
        // gridId: this.gridName,
        depid:this.departmentId,
        page: this.pageNum,
        size: this.pageSize,
        module: 4
      }
      this.tableLoading = true
      this.$http.getCarViolationList(params).then(res => {
        this.data = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 获取左侧整体违规情况与饼图数据
    getCarViolateEcharts () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carTypeId == 0 ? '' : this.carTypeId,
        carTypeName: this.carTypeId == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        depid:this.departmentId,
        startDate: this.startTime,
        endDate: this.endTime,
        // gridId: this.gridName,
        module: 4
      }
      if (this.startTime == this.endTime) {
        params.date = this.startTime
      }
      this.$http.getCarViolateEcharts(params).then(res => {
        this.errData = res.result
        let arr = []
        res.result.pie.forEach((item, index) => {
          arr.push([item.rule_type_name, item.count])
        })
        this.violateChartData = arr
      })
    },
    // 日历数据
    getCarViolateCalendar () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carTypeId == 0 ? '' : this.carTypeId,
        carTypeName: this.carTypeId == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        depid:this.departmentId,
        startDate: this.startCalendarTime,
        endDate: this.endCalendarTime,
        // gridId: this.gridName,
        module: 4
      }
      this.$http.getCarViolateCalendar(params).then(res => {
        this.calendarArr = res.result
        this.maxDateCount = Math.max.apply(Math, this.calendarArr.map((item, index) => { return item.count }))
      })
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
      this.getCarViolateEcharts()
      this.getCarViolateCalendar()
    },
    // 重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.carTypeId = ''
      this.carTypeName = ''
      this.violateType = ''
      this.remoteCar =''
      this.carNumber = ''
      this.carNumberId = ''
      this.departmentId = ''
      this.departmentType = ''
      this.departmentName = ''
      // this.gridName = ''
      // this.timeInit()
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs";
@import "@/scss/iviewCssReset/adminCalendar";
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    margin-top: 16px;
    .left {
      width: 360px;
      height: calc(100vh - 170px);
      padding: 16px;
      background-color: #fff;
      overflow: auto;
      .calender {
        .calendar-top {
          position: relative;
          .calendar-top-icon {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        .calender-bottom {
          display: flex;
          li {
            display: flex;
            align-items: center;
            margin-right: 10px;
            .calender-color {
              width: 20px;
              height: 14px;
              margin-right: 10px;
            }
          }
        }
        ::v-deep {
          .success {
            background-color: #19be6b;
            color: #fff;
          }
          .warning {
            background-color: #ff9900;
            color: #fff;
          }
          .exception {
            background-color: #ed4014;
            color: #fff;
          }
        }
      }
      .left-list {
        display: flex;
        .left-list-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            color: #a9aab5;
            line-height: 36px;
          }
          .left-list-icon {
            color: #0079fe;
            padding: 5px;
            background-color: #f2f2f2;
            border-radius: 50%;
          }
        }
      }
      .noData {
        img {
          width: 170px;
          height: 100px;
        }
      }
    }
    .table {
      background-color: #fff;
      margin-left: 16px;
      flex: 1;
    }
  }
}
</style>